import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Blog } from "./components/blog";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import { FaAngleUp } from 'react-icons/fa';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import TagManager from 'react-gtm-module';
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    let header = document.getElementById("menu");
    let title = document.getElementById("menu-title");
    let link = document.getElementById("bs-navbar-collapse");

    const handleScroll = () => {
      if (window.scrollY > 70) {
        setShowTopBtn(true);
        header.style.background = "white";
        header.style.textShadow = "1px 1px rgba(0, 0, 0, 0.7)";
        updateLinkColor("#8dafca");
        title.style.color = "8dafca";
        link.style.textShadow = "0px 0px rgba(0, 0, 0, 0.7)";
      } else {
        setShowTopBtn(false);
        header.style.background = "transparent";
        header.style.textShadow = "2px 2px rgba(0, 0, 0, 0.1)";
        updateLinkColor("#fff");
        title.style.color = "#fff";
        title.style.textShadow = "transparent";
      }
    };

    const updateLinkColor = (color) => {
      // Update the link color here
      const links = document.querySelectorAll("#menu a");
      links.forEach((link) => {
        link.style.color = color;
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log('showTopBtn:', showTopBtn);
  }, [showTopBtn]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Blog data={landingPageData.Blog} />
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />
      <button onClick={scrollToTop} className={`back-to-top ${showTopBtn ? 'visible' : ''}`}>
        <FaAngleUp />
      </button>
    </div>
  );
};

const tagManagerArgs = {
  gtmId: 'G-2F7PW5CNMK'
};
TagManager.initialize(tagManagerArgs);

export default App;
