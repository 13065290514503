import { useState, useEffect } from "react";
import axios from "axios";

const url = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nopsa.ventures&count=6&api_key=vglug8nxmmy1h95worcsvg61j52dhbi76j6mhxwv";

export const Blog = (props) => {
    const [articleData, setArticleData] = useState(null);

    const getData = async () => {
        await axios.get(url)
            .then((response) => {
                setArticleData(response.data.items)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
            getData();
        },
        [])

    return (
        <div id="blog">
         <div className="text-center">
           <div className="container">
              <div className="row">
               <div className="section-title">
                 <h2>Latest Articles</h2>
               </div>
           <div className="row">

        <div className="MediumArticles" style={{margin: "5vh 0", color: "#232E44"}}>
            {
                articleData !== null ?
                    <div>
                        <div className="container">
                            <div className="row">
                                {articleData.map((item, index) => {
                                    return (
                                        <div key={index} className="col-lg-4">
                                            <div className="card" style={{height: "20vh", margin: "1vh"}}>
                                                <div className="card-body" style={{overflow: "hidden", height: "14vh"}}>
                                                    <h5 className="card-title">
                                                        <a href={item.link} target="_blank" rel="noreferrer"
                                                           style={{
                                                               color: "#ffffff",
                                                               fontSize: "1.5rem",
                                                               textDecoration: "none",
                                                               fontWeight: "1200"
                                                           }}> {item.title}
                                                        </a>
                                                    </h5>
                                                    <div style={{}}>
                                                        {item.categories.map((item_, index) => {
                                                            return (
                                                                <a style={{
                                                                    color: "#000000",
                                                                    fontSize: "1.6vh",
                                                                    textDecoration: "none"
                                                                }}
                                                                   href={`https://medium.com/tag/${item_}`}
                                                                   target="_blank" rel="noreferrer">#{item_}, </a>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div> : <span/>
            }
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Blog;