// Navigation.js
import LogoImage from '../../src/images/nopsa_logo.png';
import { scroll } from "../App";

export const Navigation = (props) => {
  const scrollToTop = () => {
    scroll.animateScroll(0);
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top align-right'>
      <div className='container'>
        <div className='navbar-header'>
          <img src={LogoImage} alt="Logo" className="logo-image" onClick={scrollToTop} />
          <span id="menu-title" className="logo-text" onClick={scrollToTop}>Nopsa Ventures</span>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-navbar-collapse'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
        </div>
        <div
          className='collapse navbar-collapse '
          id='bs-navbar-collapse'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#blog' className='page-scroll'>
                Blog
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
